export default {
  //baseUrl: "http://localhost:8081/api/front/",
  baseUrl: "https://xl-vaping.com/api/front/",

  menu: {
    getMenus: "menu/getMenus",
  },

  home: {
    getHomeData: "home/getHomeData",
    getFollowUs: "home/getFollowUs",
    
    getFollowUsLink: "home/getFollowUsLink",
  },

  product: {
    getProductsByType: "product/getProductsByType",
    getPts: "product/getPts",
    getProductDetail: "product/getProductDetail",
    getPbi: "product/getPbi",
  },

  about: {
    getAboutInfo: "about/getAboutInfo",
  },

  contact: {
    sendMessage: "contact/sendMessage",
  },

  support: {
    getSupportInfo: "support/getSupportInfo",
  },

  footer: {
    getFooterInfo: "footer/getFooterInfo",
    getOtherPpi: "footer/getOtherPpi",
    getOtherTci: "footer/getOtherTci",
  },
};
