import { post } from "./http";
import api from "./api";

export function getFooterInfo(params) {
  return post(api.footer.getFooterInfo, params);
}

export function getOtherPpi(params) {
  return post(api.footer.getOtherPpi, params);
}

export function getOtherTci(params) {
  return post(api.footer.getOtherTci, params);
}
