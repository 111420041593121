import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    warningDialogHasShow: sessionStorage.getItem("warningDialogHasShow"),
  },
  getters: {},
  mutations: {
    updateWarningDialogHasShow(state, hasShow) {
      state.warningDialogHasShow = hasShow;
      sessionStorage.setItem("warningDialogHasShow", hasShow);
    },
  },
  actions: {},
  modules: {},
});
