<template>
  <v-app id="app">
    <v-main class="app-main">
      <div>
        <div class="top-warning d-flex justify-center align-center">
          WARNING: This product contains nicotine. Nicotine is an addictive
          chemical.
        </div>

        <div class="header-container" v-if="$route.path !== '/'">
          <v-row no-gutters>
            <v-col
              v-if="!$vuetify.breakpoint.xs"
              xs="0"
              sm="2"
              md="3"
              lg="4"
              xl="4"
            >
              <div class="d-flex justify-center">
                <div class="logo-container">
                  <img class="logo" src="./assets/logo1.png" alt="" />
                </div>
              </div>
            </v-col>
            <v-col xs="12" sm="8" md="6" lg="4" xl="4">
              <div class="d-flex justify-center">
                <v-row no-gutters>
                  <v-col v-for="(menu, index) in menus" :key="index">
                    <div
                      :class="`menu-item ${
                        $route.path === menu.path ? 'menu-item-active' : ''
                      }`"
                      @click="go(menu.path, menu.state, menu.link)"
                    >
                      {{ menu.name }}
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col
              v-if="!$vuetify.breakpoint.xs"
              xs="0"
              sm="2"
              md="3"
              lg="4"
              xl="4"
            >
              <div class="d-flex justify-center">
                <div class="search-container">
                  <!-- <img class="search" src="./assets/ic_search.png" alt="" /> -->
                </div>
              </div>
            </v-col>
          </v-row>
        </div>

        <router-view :key="$route.fullPath" />

        <div class="footer">
          <v-row no-gutters justify="center" class="footer-inner">
            <v-col xs="12" sm="12" md="10" lg="10" xl="8">
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                  <div class="footer-sfmu">
                    <div class="footer-sfmu-title">
                      SUBSCRIBE FOR MORE UPDATES
                    </div>

                    <div class="footer-sfmu-desc">
                      Get instant updates about our new products and special
                      promos!
                    </div>

                    <v-row class="footer-sfmu-sub-container no-gutters mt-4">
                      <v-col cols="8">
                        <input
                          v-model="email"
                          type="text"
                          maxlength="30"
                          class="footer-sfmu-sub"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-btn
                          depressed
                          dark
                          :loading="loading"
                          max-height="30"
                          :color="`${hasSendSuccess ? 'success' : ''}`"
                          class="footer-sfmu-sub-btn"
                          @click="sendMessage"
                          >Subscribe</v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                </v-col>

                <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
                  <div class="footer-fu">
                    <div class="footer-fu-title">FOLLOW US</div>
                    <div v-if="footer">
                      <img
                        class="footer-fu-ic"
                        src="./assets/ic_facebook.png"
                        alt=""
                        @click="toLink(footer.f_link)"
                      />
                      <img
                        class="footer-fu-ic"
                        src="./assets/ic_instagram.png"
                        alt=""
                        @click="toLink(footer.i_link)"
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                  <div class="footer-cu">
                    <div class="footer-cu-title">CONTACT US</div>
                    <v-row no-gutters>
                      <v-col cols="12" class="footer-cu-text">
                        Shenzhen Wuxi Technology Co.,Ltd
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" class="footer-cu-text"
                        >Email: sales@xl-vaping.com
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" class="footer-cu-text">
                        Address: No.301. Building 6, the Third Period, Huangtian
                        Yangbei Industrial Zone, Bao'an, Shenzhen, China
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>

              <div class="footer-divider"></div>

              <div class="footer-others">
                <v-row no-gutters>
                  <v-col cols="6">
                    <div class="footer-copyright">
                      Copyright © 2023 XLarge All rights reserved.
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="footer-pptc">
                      <span
                        class="footer-ppi-tci"
                        @click="blankPush('/other/privacy-pollcy')"
                        >Privacy Pollcy</span
                      >
                      |
                      <span
                        class="footer-ppi-tci"
                        @click="blankPush('/other/terms-condltions')"
                        >Terms & Condltions</span
                      >
                    </div>
                  </v-col>
                </v-row>

                <div class="footer-info">
                  California Proposition 65 Warning:This product contains
                  chemicals known to the state of California to cause cancer and
                  birth defects or other reproductive harm.
                </div>
              </div>
            </v-col>
          </v-row>
        </div>

        <img
          class="whatsapp"
          src="./assets/ic_whatsapp.png"
          @click="
            toLink(
              'https://api.whatsapp.com/send?phone=8615012962876&text=Hello'
            )
          "
        />

        <v-dialog v-model="warningDialogVisible" width="500" persistent>
          <div class="warning-dialog">
            <v-card class="black">
              <v-card-title class="justify-center">
                <img class="warning-dialog-logo" src="./assets/logo1.png" />
              </v-card-title>

              <v-card-text>
                <p class="warning-dialog-text1">
                  Website restricted to adults who are 21 years of age of older.
                  By entering this site, you are certifying that you are 21
                  years of age or older.
                </p>
                <p class="warning-dialog-text2">
                  WARNING: This product contains nicotine. Nicotine is an
                  addictive chemical. Only for adults, MINORS are prohibited
                  from buying e-cigarette.
                </p>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <div class="warning-dialog-un21" @click="onAlert">UNDER 21</div>
                <v-spacer></v-spacer>
                <div class="warning-dialog-21" @click="onClose">21+</div>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </div>
        </v-dialog>
      </div>
    </v-main>
  </v-app>
</template>

<style>
#app {
}

.app-main {
  width: 100%;
  overflow: hidden;
}

.top-warning {
  width: 100%;
  height: 80px;
  text-align: center;
  font-family: Lato-Bold;
  font-size: 1rem;
  color: #000000;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #eef0f1;
}
.header-container {
  width: 100%;
  height: 80px;
  background-color: #000000;
  text-align: center;
}
.logo-container {
  height: 70px;
}
.logo {
  height: 42px;
  margin-top: 19px;
}
.menu-item {
  width: 100%;
  font-family: Lato-Regular;
  max-width: 100px;
  height: 80px;
  line-height: 80px;
  display: inline-block;
  color: #eef0f1;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
.menu-item-active {
  border-bottom: 4px solid #ffffff;
}
.menu-item:hover {
  border-bottom: 4px solid #ffffff;
}
.search-container {
  width: 36px;
  height: 80px;
  padding: 10px;
  cursor: pointer;
}
.search {
  width: 16px;
  height: 16px;
  margin-top: 22px;
}
.footer {
  width: 100%;
  background-color: #fafafa;
}
.footer-inner {
  padding-left: 20px;
  padding-right: 20px;
}
.footer-sfmu {
  margin-top: 29px;
  margin-left: 20px;
}
.footer-sfmu-title {
  font-family: Lato-Bold;
  font-size: 12px;
  color: #000000;
}
.footer-sfmu-desc {
  font-family: Lato-Regular;
  font-size: 10px;
  color: #555555;
  margin-top: 10px;
}
.footer-sfmu-sub-container {
  width: 250px;
  height: 30px;
}
.footer-sfmu-sub {
  width: 100%;
  height: 30px;
  background-color: #ffffff;
  border-radius: 4px 0px 0px 4px;
  padding-left: 4px;
  padding-right: 4px;
  border: solid 1px #dcdcdc;
  float: left;
}
.footer-sfmu-sub-btn {
  width: 100%;
  background-color: #000000;
  font-family: Lato-Regular;
  font-size: 10px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
}
.footer-fu {
  margin-top: 29px;
  padding-left: 20px;
}
.footer-fu-title {
  font-family: Lato-Bold;
  font-size: 12px;
  color: #000000;
}
.footer-fu-ic {
  width: 32px;
  height: 32px;
  margin-top: 13px;
  margin-right: 17px;
  cursor: pointer;
}
.footer-cu {
  margin-top: 29px;
  padding-left: 20px;
}
.footer-cu-title {
  font-family: Lato-Bold;
  font-size: 12px;
  color: #000000;
  margin-bottom: 10px;
}
.footer-cu-text {
  font-family: Lato-Bold;
  font-size: 12px;
  margin-bottom: 4px;
}
.footer-divider {
  height: 1px;
  background-color: #dcdcdc;
  margin-top: 30px;
  margin-bottom: 30px;
}
.footer-others {
  margin-left: 20px;
  margin-right: 20px;
}
.footer-copyright {
  font-family: Lato-Regular;
  font-size: 10px;
  color: #555555;
}
.footer-pptc {
  font-family: Lato-Bold;
  font-size: 10px;
  color: #000000;
}
.footer-info {
  font-family: Lato-Regular;
  font-size: 10px;
  color: #555555;
  margin-top: 12px;
  padding-bottom: 27px;
}
.warning-dialog {
  border: solid 2px #fff;
}
.warning-dialog-logo {
  height: 33px;
  object-fit: cover;
  margin-top: 20px;
}
.warning-dialog-text1 {
  font-size: 12px;
  color: #fff;
  text-align: center;
  margin-top: 20px;
}
.warning-dialog-text2 {
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}
.warning-dialog-un21 {
  width: 90px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  border-radius: 2px;
  border: solid 1px #ffffff;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 12px;
}
.warning-dialog-21 {
  width: 90px;
  height: 30px;
  color: #000;
  line-height: 30px;
  text-align: center;
  background-color: #fff;
  border-radius: 2px;
  border: solid 1px #fff;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 12px;
}
.whatsapp {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 9999;
  display: none;
}
</style>

<style scoped>
.v-btn {
  text-transform: none;
  border-radius: 0px 4px 4px 0px;
}

.footer-ppi-tci {
  cursor: pointer;
}
</style>

<script>
import { getMenus } from "./components/api/menu";
import { getFooterInfo } from "./components/api/footer";
import { sendMessage } from "./components/api/contact";

export default {
  data() {
    return {
      menus: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Product",
          path: "/product",
          args: null,
        },
        {
          name: "About",
          path: "/about",
        },
        {
          name: "Contact",
          path: "/contact",
        },
        {
          name: "Support",
          path: "/support",
        },
      ],

      footer: null,

      warningDialogVisible: false,

      loading: false,
      hasSendSuccess: false,
      email: null,
    };
  },

  methods: {
    getMenus() {
      getMenus({})
        .then((res) => {
          if (res.data.state === "ok") {
            for (let i = 0; i < this.menus.length; i++) {
              let menu = this.menus[i];
              let menu2 = res.data.menus[i];
              menu.name = menu2.name;
              menu.state = menu2.state;
              menu.link = menu2.link;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getFooter() {
      getFooterInfo({})
        .then((res) => {
          if (res.data.state === "ok") {
            this.footer = res.data.footer;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    toLink(link) {
      // 打开一个新的页面
      window.open(link, "_blank");
    },

    go(path, state, link) {
      if (state === 1 || state === 2) {
        if (state === 1) {
          // 打开一个新的页面
          window.open(link, "_blank");
        } else {
          this.$router.push(path);
        }
      } else {
        if (path.indexOf("product") !== -1) {
          this.$router.push({ path: path, query: { type: 1 } });
        } else {
          this.$router.push(path);
        }
      }
    },

    blankPush(path) {
      let text = this.$router.resolve({
        path: path,
      });

      // 打开一个新的页面
      window.open(text.href, "_blank");
    },

    onClose() {
      this.warningDialogVisible = false;
      this.$store.commit("updateWarningDialogHasShow", true);
    },

    onAlert() {
      alert("Our product is not appropriate for individuals under 21.");
    },

    sendMessage() {
      if (this.loading || this.hasSendSuccess) {
        return;
      }
      if (this.email.length === 0) {
        return;
      }
      if (this.email.length > 30) {
        return;
      }
      this.loading = true;
      sendMessage({ name: "", email: this.email, message: "" })
        .then((res) => {
          if (res.data.state === "ok") {
            setTimeout(() => {
              this.loading = false;
              this.hasSendSuccess = true;
              setTimeout(() => {
                this.email = "";
                this.hasSendSuccess = false;
              }, 500);
            }, 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  mounted() {
    this.getMenus();

    this.getFooter();

    let hasShow = this.$store.state.warningDialogHasShow;
    if (!hasShow) {
      this.warningDialogVisible = true;
    }

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  },
};
</script>
