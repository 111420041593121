import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home"),
  },
  {
    path: "/product",
    name: "product",
    component: () => import("../views/product"),
  },
  {
    path: "/product-detail",
    name: "product-detail",
    component: () => import("../views/product-detail"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/contact"),
  },
  {
    path: "/support",
    name: "support",
    component: () => import("../views/support"),
  },
  {
    path: "/other/privacy-pollcy",
    name: "privacy-pollcy",
    component: () => import("../views/other/privacy-pollcy"),
  },
  {
    path: "/other/terms-condltions",
    name: "terms-condltions",
    component: () => import("../views/other/terms-condltions"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
